<template>
<P>Res</P>
</template>

<script>
export default {
  name: "MyResources"
}
</script>

<style scoped>

</style>